<template>
  <div>
    <Navbar page="Compartilhe" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
        <h1 class="font-r text-2xl md:text-3xl mb-6">Compartilhe a <b>FÁCIL LGPD</b> com consultores ou empresas e ganhe! </h1>
        <h2 class="text-2xl mb-3 font-semibold mt-5">Ganhe ao indicar a plataforma.</h2>
        <p class="text-lg font-normal">
          Ao indicar a Fácil, você receberá 10% do valor da assinatura do indicado, todo mês! É isso mesmo, indicou ganhou. Compartilhe a Fácil usando os canais abaixo:
        </p>
        <div class="flex flex-wrap mb-4 mt-4">
          <ShareNetwork 
            class="text-white font-normal text-base bg-facebook hover:bg-gray-800 rounded py-2 px-4 my-1"
            network="facebook"
            :url="link"
            title="Agilize, Automatize e Monitore facilmente seu Projeto de adequação à LGPD. Agilize, Automatize e Monitore facilmente seu Projeto de adequação à LGPD. 15 dias de teste grátis.."
            description="Conheça a FÁCIL LGPD. Acelere a adequação à LGPD de forma segura e descomplicada. O que você precisa, de forma objetiva, numa plataforma intuitiva e customizável."
            quote="Agilize, Automatize e Monitore facilmente seu Projeto de adequação à LGPD. 15 dias de teste grátis."
          >
              Facebook
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline font-bold" fill="#fafafa" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><circle cx="128" cy="128" r="96" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></circle><path d="M168,88H152a23.9,23.9,0,0,0-24,24V224" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></path><line x1="96" y1="144" x2="160" y2="144" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"></line></svg>
          </ShareNetwork>
          <ShareNetwork 
            class="text-white font-normal text-base bg-whatsapp hover:bg-gray-800 rounded py-2 px-4 ml-2 my-1"
            network="WhatsApp"
            :url="link"
            title="Agilize, Automatize e Monitore facilmente seu Projeto de adequação à LGPD. 15 dias de teste grátis."
            description="Conheça a FÁCIL LGPD. Acelere a adequação à LGPD de forma segura e descomplicada. O que você precisa, de forma objetiva, numa plataforma intuitiva e customizável."
            quote="Agilize, Automatize e Monitore facilmente seu Projeto de adequação à LGPD. 15 dias de teste grátis."
          >
                WhatsApp
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline" fill="#fafafa" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M45.4,177A95.9,95.9,0,1,1,79,210.6h0L45.8,220a7.9,7.9,0,0,1-9.8-9.8L45.4,177Z" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><path d="M152.1,184A79.9,79.9,0,0,1,72,103.9,28,28,0,0,1,100,76h0a6.8,6.8,0,0,1,6,3.5l11.7,20.4a8.1,8.1,0,0,1-.1,8.1l-9.4,15.7h0a48,48,0,0,0,24.1,24.1h0l15.7-9.4a8.1,8.1,0,0,1,8.1-.1L176.5,150a6.8,6.8,0,0,1,3.5,6h0A28.1,28.1,0,0,1,152.1,184Z" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
          </ShareNetwork>
          <ShareNetwork 
            class="text-white font-normal text-base bg-telegram hover:bg-gray-800 rounded py-2 px-4 ml-2 my-1"
            network="Telegram"
            :url="link"
            title="Agilize, Automatize e Monitore facilmente seu Projeto de adequação à LGPD. 15 dias de teste grátis."
            description="Conheça a FÁCIL LGPD. Acelere a adequação à LGPD de forma segura e descomplicada. O que você precisa, de forma objetiva, numa plataforma intuitiva e customizável."
            quote="Agilize, Automatize e Monitore facilmente seu Projeto de adequação à LGPD. 15 dias de teste grátis."
          >
                Telegram
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 inline" fill="#fafafa" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M88,134.9,177.9,214a8,8,0,0,0,13.1-4.2L228.6,45.6a8,8,0,0,0-10.7-9.2L33.3,108.9c-7.4,2.9-6.4,13.7,1.4,15.3Z" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><line x1="88" y1="134.9" x2="224.1" y2="36.6" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><path d="M132.9,174.4l-31.2,31.2A8,8,0,0,1,88,200V134.9" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
          </ShareNetwork>
          <ShareNetwork 
            class="text-white font-normal text-base bg-gray-700 hover:bg-gray-800 rounded py-2 px-4 ml-2 my-1"
            network="Email"
            :url="link"
            title="Agilize, Automatize e Monitore facilmente seu Projeto de adequação à LGPD. 15 dias de teste grátis."
            description="Conheça a FÁCIL LGPD. Acelere a adequação à LGPD de forma segura e descomplicada. O que você precisa, de forma objetiva, numa plataforma intuitiva e customizável."
            quote="Agilize, Automatize e Monitore facilmente seu Projeto de adequação à LGPD. 15 dias de teste grátis."
          >
              Email
              <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 w-6 h-6 inline" fill="#fafafa" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><polyline points="224 56 128 144 32 56" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline><path d="M32,56H224a0,0,0,0,1,0,0V192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56A0,0,0,0,1,32,56Z" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path><line x1="110.5" y1="128" x2="34.5" y2="197.7" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line><line x1="221.5" y1="197.7" x2="145.5" y2="128" fill="none" stroke="#fafafa" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line></svg>
          </ShareNetwork>
        </div>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-8 sm:col-span-4 2xl:col-span-3">
            <input type="text" id="linklogin" disabled :value="link" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-4 sm:col-span-2 2xl:col-span-1">
            <button @click="copyClipboard" class="text-white hover:bg-gray-600 bg-gray-500 rounded py-2 px-4 w-full">
              Copiar
            </button>
          </div>
          <div class="col-span-12 md:col-span-3">
            <h3 class="text-lg font-semibold mt-2">Código: {{ user && user.codigoIndicacao ? user.codigoIndicacao : '' }}</h3>
          </div>
        </div>
        <div v-if="user && !user.indicou">
          <hr class="mt-12 md:mt-16" />
          <h2 class="text-xl mb-3 font-normal mt-5">Alguém lhe indicou a plataforma ? Digite o código do parceiro:</h2>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-8 sm:col-span-4 2xl:col-span-3">
              <input type="text" id="codigoIndicacao" v-model="codigoIndicacao" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-4 sm:col-span-2 2xl:col-span-1">
              <button @click="vincular" class="text-white hover:bg-green-600 bg-green-500 rounded py-2 px-4 w-full">
                Vincular
              </button>
            </div>
          </div>
        </div>
        <hr v-if="indicados.length" class="mt-12 md:mt-16" />
        <h2 v-if="indicados.length" class="text-2xl mb-3 font-semibold mt-2">Seus indicados:</h2>
        <div v-if="indicados.length" class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                            Nome
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                            Email
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                            Data Cadastro
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in indicados" :key="item._id">
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="flex items-center">
                                <div class="text-sm text-gray-500">
                                    {{item.name ? item.name : ''}}
                                </div>
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="flex items-center">
                                <div class="text-sm text-gray-500">
                                    {{item.email ? item.email : ''}}
                                </div>
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="flex items-center">
                                <div v-if="item.data" class="text-sm text-gray-500">
                                    {{ item.data | moment("DD/MM/YYYY") }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
        user: null,
        codigoIndicacao: '',
        link: 'https://painel.facillgpd.com.br/registrar',
        indicados: []
    }
  },
  methods: {

    async start() {
        const userReq = await this.$http.post('/v1/users/me');
        this.user = userReq.data;
        this.link = `https://painel.facillgpd.com.br/registrar?codigo=${userReq.data.codigoIndicacao}`;

        const indicadosReq = await this.$http.post('/v1/users/listIndicados',{ all: true });
        this.indicados = indicadosReq.data.data; 
    },

    async vincular() {
      const req = await this.$http.post(`/v1/users/vincularIndicacao`, { codigoIndicacao: this.codigoIndicacao });
      if (req.data.success) {
          this.$vToastify.success("Salvo ao realizar vínculo!");
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    copyClipboard () {
        navigator.clipboard.writeText(this.link);
        this.$vToastify.success("Copiado com sucesso!");
    },

  },
  async beforeMount() {
    this.start();
  },
}
</script>